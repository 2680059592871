@mixin sgbs-badge-size($height, $font-size, $square-size) {
  $padding-x: ($height - $square-size)*0.5;
  
  display: inline-flex;
  padding: $badge-padding-y $padding-x;
  height: $height;

  @if ($font-size) {
    font-size: $font-size;
  }

  &.badge-prepend-square {
    &::before {
      position: relative;
      margin-right: $padding-x;
      width: $square-size;
      height: $square-size;
      left: auto;
    }
  }
}
