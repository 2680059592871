//
// Base styles
//

@import 'bootstrap/scss/breadcrumb';

//
// Breadcrumb Item
//

.breadcrumb-item {
  @include variable-weight($sgbs-breadcrumb-item-font-weight);
}
