// Selected text background color
::selection {
  background-color: $sgbs-selection-bg-color;
  color: $sgbs-selection-color;
}

// Remove search input Chrome cross
input[class*="no-cancel-button"]::-webkit-search-decoration,
input[class*="no-cancel-button"]::-webkit-search-cancel-button,
input[class*="no-cancel-button"]::-webkit-search-results-button,
input[class*="no-cancel-button"]::-webkit-search-results-decoration {
  display: none;
}
