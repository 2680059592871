.btn.btn-discreet-success {
  $light-success: text-color-light-background('bg-lvl1', 'success');

  color: $light-success;

  &:hover {
    color: $light-success;
  }

  &:focus,
  &.focus {
    color: $light-success;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: button-active-color($primary-alt);
  }
}

.btn.btn-discreet-warning {
  $light-warning: text-color-light-background('bg-lvl1', 'warning');

  color: $light-warning;

  &:hover {
    color: $light-warning;
  }

  &:focus,
  &.focus {
    color: $light-warning;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: button-active-color($primary-alt);
  }
}
