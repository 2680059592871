//
// Base styles
//

@import 'bootstrap/scss/forms';

//
// Textual form controls
//

input.form-control {
  height: $input-height-md;
  line-height: $input-height-md;

  &.form-control-sm {
    height: $input-height-sm;
    line-height: $input-height-sm;
  }
  &.form-control-lg {
    height: $input-height-lg;
    line-height: $input-height-lg;
  }
  &.form-control-xl {
    height: $input-height-xl;
    line-height: $input-height-xl;
  }
}

.form-control,
.custom-select {
  @include variable-weight($font-weight-normal);

  &::placeholder {
    @include variable-weight($sgbs-input-placeholder-font-weight);
  }

  &.disabled,
  .disabled &,
  &:disabled {
    box-shadow: $sgbs-input-disabled-box-shadow;
    opacity: $sgbs-disbaled-element-opacity;
  }

  &.readonly,
  &[readonly] {
    box-shadow: $sgbs-input-readonly-box-shadow;
    background-color: $input-disabled-bg;
  }

  .disabled &,
  &.disabled,
  &[disabled] {
    background-color: $input-disabled-bg;
  }
}

.form-control-alt {
  background: transparent;
  box-shadow: inset 0 0 0 1px $sgbs-form-control-alt-border-color;
}

.form-control-plaintext {
  &:focus {
    outline: none;
  }
}

// Placeholders over Light backgrounds
@each $background in $sgbs-adaptive-backgrounds-light {
  .#{$background} {
    .form-control,
    .custom-select {
      &::placeholder {
        color: light-discreet-color($background, 'secondary');
      }
    }
  }
}

// Placeholders over Dark backgrounds
@each $background in $sgbs-adaptive-backgrounds-dark {
  .#{$background} {
    .form-control,
    .custom-select {
      &::placeholder {
        color: dark-discreet-color($background, 'secondary');
      }
    }
  }
}

//
// Labels
//

.form-group > label {
  color: $sgbs-form-label-color;
}

.form-check-label {
  line-height: $sgbs-font-check-label-line-height;
}

.col-form-label {
  text-align: right;
}

.col-form-label-xl {
  padding-top: $sgbs-input-padding-y-xl;
  padding-bottom: $sgbs-input-padding-y-xl;
  font-size: $sgbs-input-font-size-xl;
  line-height: $sgbs-input-line-height-xl;
}

.input-group-xl .form-control,
.form-control-xl {
  height: $sgbs-input-height-xl;
  padding: $sgbs-input-padding-y-xl $sgbs-input-padding-x-xl;
  font-size: $sgbs-input-font-size-xl;
  line-height: $sgbs-input-line-height-xl;
  @include border-radius($sgbs-input-border-radius-xl);
}

select:not([size]):not([multiple]) {
  &.form-control {
    appearance: none;
    background-image: $sgbs-input-select-indicator;
    background-repeat: no-repeat;
    background-position: right 0 center;
    padding-right: $input-height-inner;

    &:disabled {
      background-image: $sgbs-input-disabled-select-indicator;
    }
    &::-ms-expand {
      opacity: 0;
    }

    option,
    optgroup {
      // Ensure that the `options` in a `select.form-control` have a defined
      // background color. Especially on dark theme.
      background: $body-bg;
    }
  }
}

//
// Form groups
//

.form-group > label {
  @include variable-weight($font-weight-medium);
}

//
// Form validation
//

@include form-validation-state(
  'warning',
  $sgbs-form-feedback-warning-color,
  ''
);

//
// Chrome type=search input custom cancel button
// https://developer.mozilla.org/fr/docs/Web/CSS/::-webkit-search-cancel-button
//
@mixin search-cancel-button($size) {
  -webkit-appearance: none;

  height: #{$size}px;
  width: #{$size}px;
  background-image: str-replace(
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='#{$size}' height='#{$size}' viewBox='0 0 24 24'%3E%3Cpath fill='#{$primary}' d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E"),
    '#',
    '%23'
  );
}

.form-control[type='search']::-webkit-search-cancel-button {
  @include search-cancel-button(16);
}

.form-control-sm[type='search']::-webkit-search-cancel-button {
  @include search-cancel-button(14);
}

.form-control-lg[type='search']::-webkit-search-cancel-button {
  @include search-cancel-button(18);
}

.form-control-xl[type='search']::-webkit-search-cancel-button {
  @include search-cancel-button(20);
}
