//
// Base styles
//

@import 'bootstrap/scss/input-group';

//
// Input group
//

@include input-group-structure($input-padding-x, $input-height);

.input-group-merged {
  position: absolute;
  top: 0;
  bottom: 0;
  align-items: center;

  .icon {
    color: $secondary;
  }

  // Unset z-index usefull in input-group but causing issue in merged input case
  // When .form-control is focused
  // With the z-index: 3 given by Bootstrap
  // Append or prepend button is not clickable anymore...
  + .form-control:focus {
    z-index: unset;
  }
}

// Placeholders over Light backgrounds
@each $background in $sgbs-adaptive-backgrounds-light {
  .#{$background} {
    .input-group-merged .icon {
      color: light-discreet-color($background, 'secondary');
    }
  }
}

// Placeholders over Dark backgrounds
@each $background in $sgbs-adaptive-backgrounds-dark {
  .#{$background} {
    .input-group-merged .icon {
      color: dark-discreet-color($background, 'secondary');
    }
  }
}

.input-group-sm {
  font-size: $font-size-sm;
  @include input-group-structure($input-padding-x-sm, $input-height-sm);
}

.input-group-lg {
  font-size: $font-size-lg;
  @include input-group-structure($input-padding-x-lg, $input-height-lg);
}

//
// SG Bootstrap font size specifics overrides
//
// Bootstrap use the same font size vars for every components
// ($font-size-base, $font-size-sm, $font-size-lg, ...)
// We do not want to apply $font-size-lg on btn-lg and form-control-lg
// We should declare new variables to set the button and input font sizes, different from
// global font sizes
//
.input-group-lg > .form-control,
.form-control-lg {
  font-size: $sgbs-btn-input-font-size-lg;
}

// Additional rules missing because XL was introduced and did not exist in bootstrap
.input-group-xl {
  font-size: $font-size-lg; // refers to https://sgithub.fr.world.socgen/sgbootstrap/sg-bootstrap-v4/issues/2179

  .input-group-prepend,
  .input-group-append {
    height: $sgbs-input-height-xl;
  }

  // Based on bootstrap code set for LG and SM sizes
  // https://github.com/twbs/bootstrap/blob/v4.1.3/scss/_input-group.scss#L120-L135
  > .form-control,
  > .input-group-prepend > .input-group-text,
  > .input-group-append > .input-group-text,
  > .input-group-prepend > .btn,
  > .input-group-append > .btn {
    height: $sgbs-input-height-xl;
    padding: $sgbs-input-padding-y-xl $sgbs-input-padding-x-xl;
    font-size: $font-size-lg; // refers to https://sgithub.fr.world.socgen/sgbootstrap/sg-bootstrap-v4/issues/2179
    line-height: $sgbs-input-line-height-xl;
  }

  @include input-group-structure(
    $sgbs-input-padding-x-xl,
    $sgbs-input-height-xl
  );
}

.input-group-append.input-group-merged {
  position: absolute;

  .input-group-text {
    background: transparent;
  }
}

//
// Textual addons
//

.input-group-text {
  padding: $input-padding-y ($input-padding-x * 0.8);
  opacity: 1;
}
