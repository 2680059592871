@mixin set-color-opacity-rules($colors) {
  @each $opacity in map-keys($sgbs-opacities) {
    @each $color_name, $color_value in $colors {
      .bg-#{$color_name}-alpha-#{$opacity} {
        background: rgba(
          $color_value,
          map-get($sgbs-opacities, $opacity)
        ) !important;
      }

      .alpha-#{$opacity} {
        opacity: map-get($sgbs-opacities, $opacity) !important;
      }

      .text-#{$color_name}-alpha-#{$opacity} {
        color: rgba(
          $color_value,
          map-get($sgbs-opacities, $opacity)
        ) !important;
      }

      .border-#{$color_name}-alpha-#{$opacity} {
        border-color: rgba(
          $color_value,
          map-get($sgbs-opacities, $opacity)
        ) !important;
      }
    }
  }
}

@include set-color-opacity-rules($theme-colors);
@include set-color-opacity-rules($sgbs-bg-colors);
@include set-color-opacity-rules($sgbs-bg-alt-colors);
@include set-color-opacity-rules($sgbs-secondary-colors);
