//
// Base styles
//

@import 'bootstrap/scss/navbar';

.navbar {
  padding: $sgbs-navbar-padding-mobile-y $sgbs-navbar-padding-mobile-x;

  @include media-breakpoint-up(lg) {
    padding: $navbar-padding-y $navbar-padding-x;
  }
}

.navbar-nav {
  .nav-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $sgbs-navbar-link-color;
    @include variable-weight($font-weight-semibold);

    &:hover {
      color: $sgbs-navbar-active-link-color;
    }
  }
}

.navbar-nav .active .nav-link,
.navbar-nav .nav-link.active {
  color: $sgbs-navbar-active-link-color;
}

.navbar {
  .sgwt-account-center {
    .btn-flat-black {
      color: $sgbs-navbar-icons-color;

      &:hover {
        color: $sgbs-navbar-icons-color !important;
        background: rgba(
          $sgbs-navbar-icons-color,
          map-get($sgbs-opacities, 'md')
        );
      }

      &:focus,
      &.focus {
        color: $sgbs-navbar-icons-color !important;
        background-color: rgba(
          $sgbs-navbar-icons-color,
          map-get($sgbs-opacities, 'md')
        );
        box-shadow: none;
      }

      &:not([disabled]):not(.disabled).active,
      &:not([disabled]):not(.disabled):active {
        background-color: rgba(
          $sgbs-navbar-icons-color,
          map-get($sgbs-opacities, 'lg')
        );
      }
    }
  }
}

.navbar div.sgwt-account-center-user-info > a {
  &:hover {
    color: $primary !important;
  }
}

.sgwt-account-center-user {
  color: $sgbs-navbar-service-color;
  @include variable-weight($font-weight-medium);
}

.navbar .d-flex:nth-child(2) > svg {
  path,
  text {
    fill: $sgbs-navbar-service-color;
  }
}

//
// Navbar toggler
//

.navbar-toggler {
  // We changed the default button to a "Menu" button.
  @include variable-weight($sgbs-navbar-toggler-font-weight);
  min-height: $sgbs-navbar-toggler-height;
  color: $primary;
}

.navbar-brand {
  margin-right: 0;

  svg {
    border: 1px solid $sgbs-navbar-border;
  }

  @include media-breakpoint-up(xl) {
    margin-right: $navbar-padding-x;
  }
}

//
// Expand responsive classes
//
// Change default link padding
// Inspired fy https://github.com/twbs/bootstrap/blob/v4.0.0/scss/_navbar.scss#L170-L173

.navbar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-up($next) {
        .navbar-nav {
          .nav-link {
            justify-content: flex-start;
            padding-right: $sgbs-navbar-link-padding-x;
            padding-left: $sgbs-navbar-link-padding-x;
          }
        }
      }
    }
  }
}

//
// Navbar with light background color
//

.navbar-light {
  // Force block text color on `.navbar-light` because the $body-color can't be
  // trusted (as we have a dark theme).
  color: $cape-cod;

  .navbar-brand {
    // Override of the `$navbar-light-active-color` for the brand color
    color: $navbar-light-color;
  }
}

.sgbs-navbar-interactive {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 30px 32px;
  background: transparent;
  transition: padding 200ms linear, background 200ms linear;
  z-index: 999999;

  &.sgbs-navbar-interactive-animated {
    padding: 1rem 1.5rem;
    background: #fff;
  }
}

//
// Container template cases
//

.container > .navbar,
.navbar.container {
  padding-left: $grid-gutter-width / 2;
  padding-right: $grid-gutter-width / 2;
}
