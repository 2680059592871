//
// Component Loaders
//

@mixin rectangle-ratio($size) {
  height: $size * $sgbs-blinker-rectangle-ratio;
}

.blinker {
  @extends .spinner-grow;
  width: $blinker-width-md !important;
  height: $blinker-width-md !important;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: transparent;
  border-radius: 0;
  opacity: 1;
  animation: none;

  &::after {
    @include rectangle-ratio($blinker-width-md);
    content: '';
    display: block;
    background: $sgbs-spinner-color;
    animation: blink $sgbs-spinner-speed infinite;
  }
}

@keyframes blink {
  0% {
    background: $sgbs-spinner-color;
  }
  40% {
    background: transparent;
  }
  100% {
    background: $sgbs-spinner-color;
  }
}

.blinker-sm {
  width: $blinker-width-sm !important;
  height: $blinker-width-sm !important;
  &::after {
    @include rectangle-ratio($blinker-width-sm);
  }
}
.blinker-md {
  width: $blinker-width-md !important;
  height: $blinker-width-md !important;
  &::after {
    @include rectangle-ratio($blinker-width-md);
  }
}
.blinker-lg {
  width: $blinker-width-lg !important;
  height: $blinker-width-lg !important;
  &::after {
    @include rectangle-ratio($blinker-width-lg);
  }
}
.blinker-xl {
  width: $blinker-width-xl !important;
  height: $blinker-width-xl !important;
  &::after {
    @include rectangle-ratio($blinker-width-xl);
  }
}

//////////////////////////////////////////////////////////////////////

//
// Rotating border
//

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

%spinner {
  content: '';
  display: inline-block;
  width: $spinner-width-md;
  height: $spinner-width-md;
  vertical-align: text-bottom;
  border: $spinner-border-width-md solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner {
  @extend %spinner;
}

.spinner-sm {
  width: $sgbs-spinner-width-sm;
  height: $sgbs-spinner-width-sm;
  border-width: $spinner-border-width-sm;
}
.spinner-md {
  width: $spinner-width-md;
  height: $spinner-width-md;
  border-width: $spinner-border-width-md;
}
.spinner-lg {
  width: $spinner-width-lg;
  height: $spinner-width-lg;
  border-width: $spinner-border-width-lg;
}
.spinner-xl {
  width: $spinner-width-xl;
  height: $spinner-width-xl;
  border-width: $spinner-border-width-xl;
}